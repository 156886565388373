<template>
  <v-btn
    v-if="create"
    @click="deleteConfirm"
  >
    <span>{{'action.delete'| t}}</span>
    <v-icon>fa fa-trash</v-icon>
  </v-btn>
</template>

<script>
import formActionMixins from '@/components/form/formAction/formActionMixins'
export default {
  mixins: [formActionMixins],
  methods: {
    deleteConfirm() {
      this.$apopup.base({
        title: this.$t(`delete.confirm`),
        applyCallback: async () => {
          if(this.confirmIdentity != true) {
            this.removeRequest()
            return
          }

          this.$helper.account.verify(this.removeRequest)
        },
      })
    },
    async removeRequest() {
      try {
        await this.formApi.deleteApi(this.target)
        this.$snotify.success(null, this.$t('delete.successfully') )
        this.redirect()
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t('error.unexpect'),
          this.$t('delete.failure'),
        )
      }
    },
    redirect() {
      if(typeof this.formConfig.listRoute === 'function') {
        this.$router.replace(this.formConfig.listRoute())
        return
      }

      this.$router.go(-1)
    },
  },
  computed: {
    create() {
      if(this.loadFailure) return false
      if(this.dataAction.delete === false) return false
      return true
    },
    confirmIdentity() {
      if(this.loadFailure) return false
      if(!this.dataAction) return false
      if(!this.dataAction.delete) return false
      return this.dataAction.delete.confirmIdentity === true
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
